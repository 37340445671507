<!-- eslint-disable vue/no-v-html -->
<template>
	<b-row>
		<b-col>
			<b-alert
				show
				variant="danger">
				<b-row>
					<b-col class="text-center">
						<h3>{{ translate('account_canceled_title') }}</h3>
						<p
							v-for="(paragraph, index) in translate('account_canceled_text')"
							:key="index"
							v-html="paragraph" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-center mt-2">
						<button
							class="btn btn-primary"
							:class="['sm','xs','md'].includes(windowWidth) ? 'button-style' : ''"
							@click="$router.push({ name: 'Renewal' })">
							{{ translate('reactivate_account_title') }}
						</button>
					</b-col>
				</b-row>
			</b-alert>
		</b-col>
	</b-row>
</template>

<script>

import { AccountCanceled } from '@/translations';
import { distributor } from '@/settings/Roles';
import { FINAL_STATUSES_CAN_LOGIN } from '@/settings/Statuses';
import { CUSTOMER_SERVICE_EMAIL } from '@/settings/General';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'AccountCanceled',
	messages: [AccountCanceled],
	mixins: [WindowSizes],
	data() {
		return {
			distributor,
			CUSTOMER_SERVICE_EMAIL,
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (typeof FINAL_STATUSES_CAN_LOGIN[vm.$user.details().status] === 'undefined' && vm.$user.details().type === vm.distributor) vm.$router.replace({ name: 'MainHome' });
		});
	},
};
</script>
<style scoped>
.button-style {
	max-width: 250px;
}
</style>
